.container {
  position: relative;
  padding: $md-1 $sm-2 $sm-3 $sm-2;

  &--no-left {
    padding-left: 0;
  }

  &--less-bottom {
    padding-top: $xs-1;
  }

  @include for-small-desktop-up() {
    padding: $md-1 $md-4 $sm-3 $md-4;

    &--less-right {
      padding-right: $md-1;
    }

    &--less-left {
      padding-left: $md-1;
    }
  }
}
