.contrast {
  display: inline-flex;
  align-items: center;
  gap: $sm-1;
  padding: 2px 12px;
  border-radius: 12px;

  &--red {
    color: $red-800;
    background: $red-200;
  }

  &--orange {
    color: $orange-800;
    background: $orange-200;
  }

  &--yellow {
    color: $yellow-800;
    background: $yellow-200;
  }

  &--green {
    color: $green-800;
    background: $green-200;

    &:hover.contrast--clickable {
      background: $green-300;
    }
  }

  &--teal {
    color: $teal-800;
    background: $teal-200;
  }

  &--blue {
    color: $blue-800;
    background: $blue-200;
  }

  &--clickable {
    user-select: none;
    cursor: pointer;
  }

  &--outline {
    border: 1px solid $blue-400;

    &:hover {
      background: var(--blue-subtle);
    }
  }
}

.italic {
  font-style: italic;
}

.header {
  display: block;
  color: var(--header);
  font-size: $text-xl;

  &--large {
    font-size: $text-4xl;
  }
}

.sub-header {
  color: $grey-500;
  font-size: 0.875rem;
  font-style: italic;
}

.link {
  color: $blue-400;
  text-decoration: underline;
  user-select: none;
  cursor: pointer;

  &:hover {
    color: $blue-500;
  }
}
