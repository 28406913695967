.btn {
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: bold;
  transition: color 100ms ease-in, background 100ms ease-in;
  cursor: pointer;

  &--block {
    display: block;
  }

  &--center {
    display: block;
    margin: $sm-3 auto;
  }

  &--lg {
    padding: 1rem 1.75rem;
    font-size: 1.25rem;
  }

  &--sm {
    padding: 0.5rem 0.625rem;
    font-size: 0.875rem;
  }

  &--xs {
    padding: 0.375rem 0.5rem;
    font-size: 0.75rem;
  }

  &--primary {
    color: white;
    background: $blue-600;

    &:hover {
      background: $blue-700;
    }

    &:active {
      box-shadow: 0 0 0 3px $blue-400;
    }
  }

  &--primary-outline {
    color: $blue-500;
    background: transparent;
    border: 1px solid $blue-400;

    &:hover {
      background: var(--blue-subtle);
    }

    &:active {
      box-shadow: 0 0 0 3px $blue-200;
    }
  }

  &--secondary {
    color: white;
    background: $grey-500;

    &:hover{
      background: $grey-600;
    }

    &:active {
      box-shadow: 0 0 0 3px $grey-400;
    }
  }

  &--secondary-outline {
    color: var(--sub-text);
    background: transparent;
    border: 1px solid $grey-500;

    &:hover {
      background: var(--subtle);
    }

    &:active {
      box-shadow: 0 0 0 3px $grey-400;
    }
  }

  &--danger {
    color: white;
    background: $red-600;

    &:hover {
      background: $red-700;
    }

    &:active {
      box-shadow: 0 0 0 3px $red-400;
    }
  }

  &--tertiary {
    padding: 0;
    color: var(--sub-text);
    background: inherit;

    &:hover {
      color: var(--text);
    }
  }

  &--tertiary-outline {
    color: $grey-600;
    background: inherit;
    border: 1px solid $grey-500;

    &:hover {
      background-color: $grey-100;
    }
  }

  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &--transparent {
    background: transparent;
    border-color: transparent;
  }
}

.controls {
  position: absolute;
  top: 8px;
  right: 20px;
}
