.nav-content-grid {
  display: grid;
  position: relative;
  grid-template-columns: minmax(0, 1fr);
  gap: $md-2;

  @include for-tablet-landscape-up() {
    grid-template-columns: ($lg-3 - 1rem) minmax(0, 1fr);
  }

  &--small-nav {
    grid-template-columns: ($lg-2 + 1rem) minmax(0, 1fr);
  }
}

.labeled-data-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $sm-4;

  &__label {
    justify-self: end;
  }
}