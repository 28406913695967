.margin-top {
  margin-top: $sm-4;

  &--sm {
    margin-top: $sm-2;
  }

  &--lg {
    margin-top: $md-2;
  }

  &--lg-negative{
    margin-top: -$md-2;
  }
}

.margin-bottom {
  margin-bottom: $sm-4;

  &--none {
    margin-bottom: 0 !important;
  }

  &--sm {
    margin-bottom: $sm-2;
  }

  &--lg {
    margin-bottom: $md-2;
  }
}

.margin-right {
  margin-right: $sm-4;

  &--sm {
    margin-right: $sm-2;
  }

  &--lg {
    margin-right: $md-2;
  }

  &--xl {
    margin-right: $md-4;
  }
}

.margin-left {
  margin-left: $sm-4;

  &--sm {
    margin-left: $sm-2;
  }

  &--lg {
    margin-left: $md-2;
  }

  &--xl {
    margin-left: $md-4;
  }
}

.padding-bottom {
  padding-bottom: $sm-4;
}

.padding-right {
  padding-right: $sm-4;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: bold;
}

.full-height {
  height: 100%;
  margin: 0 !important;
}

.background-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  height: 75%;
}

.full-width {
  width: 100%;
}

.display-contents {
  display: contents;
}

.align-text {
  &--right {
    text-align: right;
  }
}
