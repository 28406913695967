.row {
  display: flex;
  flex-wrap: wrap;
  gap: $sm-2;

  &--inline {
    display: inline-flex;
  }

  &--column {
    flex-direction: column;
  }

  &--center {
    justify-content: center;
  }

  &--end {
    justify-content: flex-end;

    > .btn {
      margin-left: $sm-2;
    }
  }

  &--space-between {
    justify-content: space-between;
  }

  &--align-center {
    align-items: center;
  }

  &--align-last-baseline {
    align-items: last baseline;
  }

  &--align-end {
    align-items: flex-end;
  }

  &--no-wrap {
    flex-wrap: nowrap;
  }

  &--grid {
    padding: $md-1 $sm-3;
    margin-top: $sm-3;
    background: var(--subtle);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    .input-container__field {
      margin-bottom: 0;
    }
  }

  &--margin-top {
    margin-top: $sm-3;
  }

  &--margin-bottom{
    margin-bottom: $sm-3;
  }

}
