:root {
  --grey-100: hsl(204, 45%, 98%);
  --grey-200: hsl(210, 38%, 95%);
  --grey-300: hsl(214, 32%, 91%);
  --grey-400: hsl(211, 25%, 84%);
  --grey-500: hsl(214, 20%, 69%);
  --grey-600: hsl(216, 15%, 52%);
  --grey-700: hsl(218, 17%, 35%);
  --grey-800: hsl(218, 23%, 23%);
  --grey-900: hsl(220, 26%, 14%);
  --red-100: hsl(0, 73%, 97%);
  --red-200: hsl(0, 95%, 92%);
  --red-300: hsl(0, 97%, 85%);
  --red-400: hsl(0, 95%, 75%);
  --red-500: hsl(0, 88%, 68%);
  --red-600: hsl(0, 76%, 57%);
  --red-700: hsl(0, 61%, 48%);
  --red-800: hsl(0, 56%, 39%);
  --red-900: hsl(0, 47%, 31%);
  --blue-100: hsl(201, 100%, 96%);
  --blue-200: hsl(202, 81%, 86%);
  --blue-300: hsl(203, 82%, 76%);
  --blue-400: hsl(205, 79%, 66%);
  --blue-500: hsl(207, 73%, 57%);
  --blue-600: hsl(209, 62%, 50%);
  --blue-700: hsl(211, 61%, 43%);
  --blue-800: hsl(213, 49%, 34%);
  --blue-900: hsl(215, 41%, 28%);
}

.light {
  --bg: white;
  --bg-nav: var(--grey-100);
  --nav-link: var(--grey-700);
  --nav-link-hover: var(--grey-900);
  --header: var(--grey-800);
  --text: var(--grey-700);
  --sub-text: var(--grey-600);
  --subtle: var(--grey-200);
  --accent: var(--grey-900);
  --blue-header: var(--blue-900);
  --blue-text: var(--blue-800);
  --blue-accent: var(--blue-300);
  --blue-subtle: var(--blue-100);
  --card-header: var(--blue-900);
  --card-bg: white;
  --input-field: white;
  --input-border: var(--grey-300);
  --input-error: var(--red-600);
  --pagination: white;
  --opposite: var(--grey-900);
  --same: var(--grey-100);
  --card-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.12);
  --overlay: hsla(218, 8%, 50%, 0.75);
}

.dark {
  --bg: var(--grey-900);
  --bg-nav: var(--grey-800);
  --nav-link: var(--grey-300);
  --nav-link-hover: var(--grey-100);
  --header: var(--blue-100);
  --text: var(--grey-100);
  --sub-text: var(--grey-300);
  --subtle: var(--grey-700);
  --accent: var(--grey-100);
  --blue-header: var(--blue-400);
  --blue-text: var(--blue-400);
  --blue-accent: var(--blue-300);
  --blue-subtle: var(--blue-900);
  --card-header: var(--blue-100);
  --card-bg: var(--grey-800);
  --input-field: var(--grey-700);
  --input-border: var(--grey-600);
  --input-error: var(--red-500);
  --pagination: var(--grey-700);
  --opposite: var(--grey-100);
  --same: var(--grey-900);
  --card-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.24);
  --overlay: hsla(0, 0%, 0%, 0.5);
}
