@import 'abstracts/variables';

.dx-dropdowneditor.dx-dropdowneditor-field-clickable {
  display: block;
  margin: $sm-1 0;
}

.dx-selectbox-container {
  background-color: white !important;
}
.dx-swatch-custom-dark .dx-selectbox-container {
  background-color: $grey-700 !important;
}

.dx-texteditor.dx-state-focused, .dx-texteditor.dx-state-active {
  border-color: hsl(215, 98%, 82%);
  box-shadow: 0 0 0 3px hsla(215, 98%, 82%, 0.45);
  outline: none;
}

.dx-dropdowneditor {
  border-radius: 6px !important;
}

.dx-selectbox-container {
  border-radius: 6px !important;
}

.dx-datagrid .dx-header-filter {
  color: $framework-orange !important;
}
.dx-swatch-custom-dark .dx-datagrid .dx-header-filter {
  color: $framework-orange !important;
}

.dx-datagrid .dx-header-filter-empty {
  color: rgba(149, 149, 149, 0.5) !important;
}
.dx-swatch-custom-dark .dx-datagrid .dx-header-filter-empty {
  color: rgba(149, 149, 149, 0.5) !important;
}

.dx-swatch-custom-dark .dx-datagrid-header-panel {
  background-color: $grey-800;
}
.dx-swatch-custom-dark .dx-toolbar {
  background-color: $grey-800 !important;
}

.dx-datagrid .dx-scrollable-scrollbar.dx-scrollbar-horizontal,
.dx-datagrid .dx-scrollable-scrollbar.dx-scrollbar-horizontal .dx-scrollable-scroll
{
  height: 10px;
}

.dx-scrollbar-vertical,
.dx-scrollbar-vertical .dx-scrollable-scroll
{
  width: 10px;
}

// Move scrollbar to the right so that it doesn't cover menu options on the sidebar
.sidebar-scroll-view .dx-scrollbar-vertical .dx-scrollable-scroll
{
  right: -6px !important;
}

.dx-scrollable-scroll-content {
  background-color: $orange-400 !important;
}

.dx-datagrid-summary-item.dx-datagrid-text-content {
  overflow: visible !important;
}
