p, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
}

h1 {
  font-size: 1.375rem;
  line-height: 1.75rem;

  &.subhead {
    font-size: 30px;
    line-height: 36px;
  }
}

h2 {
  font-size: 1.275rem;
  line-height: 1.45rem;

  &.subhead {
    font-size: 1.45rem;
    line-height: 32px;
  }
}

h3 {
  font-size: 24px;
  line-height: 32px;

  &.subhead {
    font-size: 18px;
    line-height: 24px;
  }
}

h4 {
  font-size: 1.1rem;
  line-height: 1.85rem;

  &.subhead {
    font-size: 12px;
    line-height: 16px;
  }
}

h5 {
  font-size: 14px;
  line-height: 18px;

  &.subhead {
    font-size: 10px;
    line-height: 12px;
  }
}

h6 {
  font-size: 12px;
  line-height: 16px;

  &.subhead {
    font-size: 8px;
    line-height: 10px;
  }
}