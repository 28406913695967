.toast-container .ngx-toastr {
  padding: $sm-4;
  background-position: 14px 14px;
  border-radius: 6px;
  box-shadow: 0 4px 8px hsla(0, 0%, 0%, 0.12), 0 2px 3px hsla(0, 0%, 0%, 0.15);

  &:hover {
    box-shadow: 0 4px 8px hsla(0, 0%, 0%, 0.12), 0 2px 3px hsla(0, 0%, 0%, 0.15);
  }
}

.toast-success {
  color: $green-800 !important;
  background-color: $green-100;
  background-image: url("^assets/icons/check-circle.svg");
}

.toast-error {
  color: $red-800 !important;
  background-color: $red-100;
  background-image: url("^assets/icons/x-circle.svg");
}

.toast-warning {
  color: $yellow-800 !important;
  background-color: $yellow-100;
  background-image: url("^assets/icons/exclamation-circle.svg");
}

.toast-info {
  color: $blue-800 !important;
  background-color: $blue-100;
  background-image: url("^assets/icons/information-circle.svg");
}

.toast-title {
  margin-bottom: $sm-1;
  margin-left: $md-2;
}

.toast-message {
  margin-left: $md-2;
}
