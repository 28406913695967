.two-column-form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  row-gap: $md-1;

  &--no-padding {
    padding: 0px;
  }

  &__left {
    grid-column: span 1;
  }

  &__right {
    grid-column: span 2;
  }

  &__header {
    display: block;
    font-size: 1.2rem;
  }

  &__sub-header {
    width: 70%;
    margin-top: 10px;
    color: $grey-500;
    font-size: 0.875rem;
    font-style: italic;
  }
}

.form-separator {
  border-bottom: 1px solid $grey-300;
  margin-top: 15px;
  margin-bottom: 20px;
}