.grid-wrapper {
  position: relative;
  margin-top: $md-2;
  margin-bottom: $md-2;

  &--less-top {
    margin-top: 0;
  }

  &--less-bottom {
    margin-bottom: 0;
  }
}