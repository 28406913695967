html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  color: var(--text);
  background: var(--bg);
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}

ul, ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $blue-400;
  text-decoration: none;

  &:hover {
    color: $blue-500;
  }
}

img {
  max-width: 100%;
}

textarea {
  min-height: $lg-1;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.material-icons {
  vertical-align: middle;
}
