@import 'abstracts/variables';

.input-container {
  position: relative;
  margin-bottom: $md-2;

  &--basis-xs {
    flex-basis: ($lg-1 + .5rem);
  }

  &--basis-sm {
    flex-basis: ($lg-2 + 1rem);
  }

  &--basis-md {
    flex-basis: $lg-4;
  }

  &--basis-lg {
    flex-basis: $xl-2;
  }

  &--xs {
    flex: 1 1 $sm-1;
  }

  &--sm {
    flex: 1 1 $md-4;
  }

  &--md {
    flex: 2 1 $lg-2;
  }

  &--lg {
    flex: 3 1 $lg-3;
  }

  &--less-bottom {
    margin-bottom: 0;
  }

  &__label {
    color: var(--accent);
    font-size: $text-sm;
    white-space: nowrap;
  }

  &__label-lg {
    color: var(--accent);
    font-size: $text-lg !important;
    white-space: nowrap;
  }

  &__field {
    display: block;
    height: $md-3 - 0.375rem;
    width: 100%;
    margin: $sm-1 0;
    padding: 0 $sm-3;
    color: var(--opposite);
    background-color: var(--input-field);
    border: 1px solid var(--input-border);
    border-radius: 6px;
    overflow: initial;
    resize: none;

    &:focus {
      border-color: var(--blue-accent);
      box-shadow: 0 0 0 3px hsla(215, 98%, 82%, 0.45);
      outline: none;
    }

    .dark &:focus {
      box-shadow: 0 0 0 3px hsla(203, 82%, 76%, 0.75);
    }
  }

  &__error {
    position: absolute;
    top: 100%;
    left: $sm-1;
    color: var(--input-error);
    font-size: $text-sm;
    margin: $xs-1 0rem;
  }

  &__warning {
    position: absolute;
    top: 100%;
    left: $sm-1;
    color: $orange-400;
    font-size: $text-xs;
  }
}

.checkbox-container {
  display: inline-flex;
  flex-direction: column;

  &__item {
    margin-bottom: $md-2;
  }
}

.preview-image {
  width: auto;
  max-height: $lg-3;

  &--md {
    width: auto;
    max-height: $md-4;
  }

  &--lg {
    width: auto;
    max-height: $lg-2;
  }
}
