@import 'abstracts/variables';

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background: no-repeat center center fixed;
  background-size: cover;
  overflow: hidden;
}

.login-container {
  width: 100%;
  max-width: $xl-3;
  padding: 0 $md-1;
  margin: 0 auto;
}

.login-header {
  display: block;
  padding-left: $sm-4;
  margin-bottom: $md-2;
  font-size: $text-2xl;
  border-left: 4px solid orange;
}

.login-row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;

  &--margin-top {
    margin-top: $md-2;
  }

  &__checkbox {
    margin-bottom: $md-2;
  }

  &__link {
    margin-bottom: $md-2;
    color: $blue-500;
    font-size: $text-sm;
    transition: color ease 200ms;
    align-self: flex-end;

    &:hover {
      color: $blue-400;
      cursor: pointer;
    }

    &--less-bottom {
      margin-bottom: 0;
    }
  }
}

.submit-button {
  width: 100%;
}

.version-row {
  text-align: center;
  margin-top: $md-2;
}
