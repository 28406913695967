.remove-icon {
  color: var(--blue-accent);
  font-size: $text-xl;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.material-icons-outlined {
  &--sm {
    font-size: $sm-4;
  }
}
