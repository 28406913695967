.circle {
  display: inline-block;
  position: relative;
  height: 16px;
  width: 16px;
  border-radius: 50%;

  &::before {
    content: '';
    position: absolute;
    top: 25%;
    left: 25%;
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }

  &--red {
    background: $red-200;

    &::before {
      background: $red-400;
    }
  }

  &--orange {
    background: $orange-200;

    &::before {
      background: $orange-400;
    }
  }

  &--yellow {
    background: $yellow-200;

    &::before {
      background: $yellow-400;
    }
  }

  &--green {
    background: $green-200;

    &::before {
      background: $green-400;
    }
  }

  &--teal {
    background: $teal-200;

    &::before {
      background: $teal-400;
    }
  }

  &--blue {
    background: $blue-200;

    &::before {
      background: $blue-400;
    }
  }
}