@media print {
  @page {
    size: landscape;
    margin: 10mm 0;
  }

  .print-container {
    zoom: 75%;
  }

  .hide-print {
    display: none !important;
  }
}